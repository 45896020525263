import Blocks from "@/components/Blocks"
import Breadcrumbs from "@/components/base/breadcrumbs/breadcrumbs"
import Article from "@/components/common/article/article"
import Block from "@/components/wrappers/block"
import { usePropsObject } from "@/lib/contexts/appContext"
import { useIsStart, useTemplate } from "@/lib/helpers/template"
import classNames from "classnames"
import Image from "next/legacy/image"
import { useEffect, useState } from "react"
import { useMediaPredicate } from "react-media-hook"
import styles from "./hero.module.css"
import MemberBlurb from "./member-blurb"

export default function Hero({ block, settings }) {
  const [hasMounted, setMounted] = useState(false)
  const isMobile = useMediaPredicate("(max-width: 768px)")
  const template = useTemplate()
  const object = usePropsObject()

  // This is used due to isMobile not working before mount
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setMounted(true)
    }
    return () => {
      isMounted = false
    }
  }, [])

  const { innerBlocks } = block
  const {
    image,
    video,
    align,
    articles,
    articlesHeading,
    size,
    opacity,
    mediaType,
    mediaAuthor,
    becomeMember,
    hidePanel,
    bgColor
  } = block.attrs

  const imageSrc = isMobile && image.tall ? image.tall : image.wide
  const videoSrc = isMobile && video.tall ? video.tall : video.wide
  const hasBackground = (imageSrc || videoSrc) && mediaType !== "none"
  const blockGap = articles.length > 0 ? "normal" : block.attrs.blockGap
  const isStart = useIsStart()
  const hideBreadcrumbs = isStart || settings?.hideBreadcrumbs
  const hasGradient =
    object?.theme?.heroGradient === true && template === "campaign"

  const classes = [
    styles.hero,
    bgColor,
    {
      "has-dark-text": !hasBackground,
      "has-bg-color": mediaType === "none",
      "has-image": mediaType === "image" && imageSrc,
      "has-video": mediaType === "video" && videoSrc,
      "size-small": size === "hero-size-md",
      "size-large": size === "hero-size-lg",
      "size-giant": size === "hero-size-xl",
      "no-articles": articles.length < 0,
      "has-articles": articles.length > 0,
      "is-centered": align === "justify-center",
      "no-breadcrumbs": hideBreadcrumbs
    }
  ]

  const innerBlockSettings = {
    "core/heading": { width: "bleed" },
    "core/paragraph": { width: "bleed" }
  }

  return (
    <>
      <Block
        block={block}
        className={classNames(classes)}
        width="bleed"
        gap={blockGap}>
        <Media {...{ hasMounted, mediaType, image, imageSrc, videoSrc }} />
        <Overlay {...{ hasBackground, opacity, template, hasGradient }} />
        <Breadcrumbs hidden={hideBreadcrumbs} wrapperClassName="breadcrumbs" />
        <div className="inner-wrapper">
          <div
            className={classNames("content", {
              campaign: template === "campaign"
            })}>
            <Blocks blocks={innerBlocks} settings={innerBlockSettings} />
            <MediaAuthor mediaAuthor={mediaAuthor} />
          </div>
        </div>
        <ArticlesPanel
          {...{ articlesHeading, hidePanel, becomeMember, articles, object }}
        />
      </Block>
    </>
  )
}

const Media = ({ hasMounted, mediaType, image, imageSrc, videoSrc }) => {
  if (!hasMounted) {
    return null
  }

  if (mediaType === "image" && imageSrc) {
    return (
      <figure className="background">
        <Image
          className="object-cover object-center w-full"
          src={imageSrc.url}
          alt={image.wide.alt}
          layout="fill"
          priority
        />
      </figure>
    )
  }

  if (mediaType === "video" && videoSrc) {
    return (
      <div className="background">
        <video
          className="video-wrapper"
          preload="auto"
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}>
          <source src={videoSrc} type="video/mp4" />
        </video>
      </div>
    )
  }
}

const Overlay = ({ hasBackground, opacity, hasGradient }) => {
  if (!hasBackground) {
    return null
  }

  return (
    <>
      <div className={classNames("overlay", "dim-" + opacity)}></div>
      {hasGradient && <div className="hero-gradient"></div>}
    </>
  )
}

const ArticlesPanel = ({
  hidePanel,
  articlesHeading,
  becomeMember,
  articles,
  object
}) => {
  if (hidePanel) {
    return null
  }

  const bgColor = object?.bgColor ?? "page"

  return (
    <div className={classNames("articles-panel", bgColor)}>
      <div className="articles-wrapper">
        {articlesHeading && <h3>{articlesHeading}</h3>}
        {becomeMember && (
          <MemberBlurb
            tag={becomeMember.tag}
            heading={becomeMember.heading}
            CTATracking={{
              event: "hero-cta",
              type: "becomeMember"
            }}
            button={becomeMember.button}
            buttonStyle={becomeMember.button === "arrow" ? "small" : "normal"}
            url={becomeMember.url}
          />
        )}
        {articles.map((article, index) => (
          <Article
            key={`article-${index}`}
            className="hero-card"
            item={article}
            showTag={false}
          />
        ))}
      </div>
    </div>
  )
}

export function MediaAuthor({ mediaAuthor }) {
  if (!mediaAuthor) {
    return null
  }

  return <div className="media-author">Foto: {mediaAuthor}</div>
}
